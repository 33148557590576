import React, { useEffect, useState } from "react";
import { Button, Form, Input, Table, notification } from "antd";
import { ColumnsType } from "antd/lib/table";
import iconAction from "../../../../assets/images/action.svg";
import iconApprove from "../../../../assets/images/apporveIcon.svg";
import shareIcon from "../../../../assets/images/shareIcon.svg";
import iconCopy from "../../../../assets/images/copy.svg";
import iconSearch from "../../../../assets/images/search.svg";
import API_SERVICE from "shared/services/api-service";
import DocumentStatus from "../../../Lead/RequiredDocuments/DocumentStatus";
import { getEnumValue, numberWithCommas } from "shared/services/Utility";
import "./Login.scss";
import { useSelector } from "react-redux";
import { RootState } from "shared/redux/store";

interface ILogin {
  setIsLoading:any;
  leadData: any;
  updateCase: any;
  getRedirectURLLink: any;
  getCashETriggerSMSLink: any;
  callbackReqDoc: any;
  callbackAdditionalField: any;
  setShowRequirementDocs: any;
  setSelectedLander: any;
  setAdditionalFields: any;
  setCurrLenderId: any;
  setCurrLeadId: any;
  setShowAdditionalFields: any;
  decisioningDataList: any;
  setTotalCount: any;
  totalCount: any;
  setIsLoginTabsListDecisioning: any;
  isLoginTabsListDecisioning: any;
}

const Login: React.FC<ILogin> = (props: ILogin) => {
  const {
    setIsLoading,
    leadData,
    updateCase,
    getRedirectURLLink,
    getCashETriggerSMSLink,
    callbackReqDoc,
    callbackAdditionalField,
    setShowRequirementDocs,
    setSelectedLander,
    setAdditionalFields,
    decisioningDataList,
    setCurrLenderId,
    setCurrLeadId,
    setShowAdditionalFields,
    setTotalCount,
    totalCount,
    setIsLoginTabsListDecisioning,
    isLoginTabsListDecisioning,
  } = props;
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [showDocumentStatus, setShowDocumentStatus] = useState(false);
  const [selectedLender, setSelectedLender] = useState({} as any);
  const [documentDetails, setDocumentDetails] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const [filterListDecision, setFilterListDecision] = useState([] as any);
  const { appColors } = useSelector((state: RootState) => state.common);
  const [listDecisioningData, setListDescisioningData] = useState([] as any);
  const lenderNamesCheck = new Set();
  const actionCheck = new Set();
  const commonFontSize = { fontSize: "13px" };

  useEffect(() => {
    if (isLoginTabsListDecisioning) {
      if (decisioningDataList?.length == 0) {
        createlist();
      } else {
        let tmpList = [...decisioningDataList];
        tmpList.sort((a: any, b: any) =>
          b.maxLoanAmount - a.maxLoanAmount
      );
        setListDescisioningData(tmpList);
        setTotalCount(decisioningDataList?.length);
      }
    }
  }, [isLoginTabsListDecisioning]);

  useEffect(() => {
    lenderNamesCheck?.clear();
    actionCheck?.clear();
  }, [listDecisioningData]);

  useEffect(() => {
    if (Object?.keys(callbackAdditionalField)?.length !== 0) {
      if (
        Object.keys(callbackReqDoc).length == 0 &&
        callbackAdditionalField?.showRequirementDocs != "false"
      ) {
        setShowRequirementDocs(true);
      }
    } else {
      setSelectedLander({});
    }
    setAdditionalFields({});
  }, [callbackAdditionalField]);

  const handlePaginationChange = (current: any, pageSize: any) => {
    // rowSpanManage(current, pageSize, tmpDecisionData);
    const filterData = listDecisioningData?.filter((item: any) => {
      return item?.lenderName
        ?.toLowerCase()
        ?.replace(/\s/g, "")
        ?.includes(searchValue?.toLowerCase()?.replace(/\s/g, ""));

      // item?.lenderName?.toLowerCase() == value?.searchParam?.toLowerCase();
    });
    setFilterListDecision(filterData);
    setPageNo(current);
    setPageSize(pageSize);
  };

  const rowSpanManage = (
    pageNo: number = 1,
    pageSize: number = 10,
    sortedList: any
  ) => {
    const lenders = [] as any;
    const tmpData = sortedList?.slice(
      (pageNo - 1) * pageSize,
      pageNo * pageSize
    );
    const list = tmpData.map((lender: any) => {
      if (!lenders?.includes(lender?.lenderName)) {
        const occurCount = tmpData?.filter(
          (data) => data.lenderName === lender?.lenderName
        ).length;
        lenders.push(lender?.lenderName);
        lender.rowSpan = occurCount;
      } else {
        lender.rowSpan = 0;
      }
      return lender;
    });
    setListDescisioningData(list);
  };

  const createlist = () => {
    const lenders =
      leadData?.caseDetails?.lenderActivityHolder?.lenderActivityDetails?.map(
        (lender: any) => {
          return { ...lender, lenderId: lender?.lenderInfoId };
        }
      );
    setListDescisioningData(lenders);
    setTotalCount(lenders?.length);
  };

  const renderDocumentStatus = (lender) => {
    const total = lender?.documentProgressDetails?.totalNumberOfDocs ?? 0;
    const submitted =
      lender?.documentProgressDetails?.documentsSuccessfullySubmitted ?? 0;
    const failed = lender?.documentProgressDetails?.failure ?? 0;
    return (
      <>
        {total > 0 && (submitted > 0 || failed > 0) && (
          <span
            className="checkbox-text small"
            style={{ cursor: "pointer", fontSize: "11px" }}
            onClick={() => {
              setDocumentDetails(
                lender?.documentProgressDetails?.submissionDetails
              );
              setSelectedLender(lender);
              setShowDocumentStatus(true);
            }}
          >
            {`Document Status: ${submitted}/${total}`}
          </span>
        )}
      </>
    );
  };

  const renderLenderNames = (rec: any) => {
    const findLender =
      leadData?.caseDetails?.lenderActivityHolder?.lenderActivityDetails?.find(
        (item) => item?.lenderInfoId == rec?.lenderId
      );
    let consentDetails: any = {};
    if ((findLender?.consentDetails?.length ?? 0) > 0) {
      consentDetails = JSON.parse(findLender?.consentDetails ?? {});
    }
    return (
      <div style={{ display: "grid" }}>
        <span style={commonFontSize}>{rec?.lenderName}</span>
        <div
          style={{
            color: "#20A74E",
            gap: "10px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          {rec?.lenderSubmissionType == "API" && (
            <span className="lender-additional-details">
              <img src={iconApprove} width={12} height={12} /> API
            </span>
          )}
          {Object.keys(consentDetails).length > 0 && (
            <span className="lender-additional-details">
              <img src={iconApprove} width={12} height={12} /> Consent
            </span>
          )}
        </div>
        {(findLender?.lenderApplicationId?.length ?? 0) > 0 && (
          <span className="lender-additional-details">
            Lender Loan ID: {findLender?.lenderApplicationId}
          </span>
        )}
        <div className="checkbox-text-div">
          {findLender?.submissionType?.includes("API") &&
            findLender?.additionalFields?.ilensId?.length > 0 && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <span className="lender-additional-details">{`iLens ID: ${JSON.parse(findLender?.additionalFields)?.ilensId
                  }`}</span>
                <Button
                  style={{
                    borderColor: "transparent",
                    background: "transparent",
                    fontSize: "12px",
                  }}
                  size="small"
                  shape="circle"
                  onClick={() => {
                    navigator.clipboard.writeText(
                      JSON.parse(findLender?.additionalFields)?.ilensId ?? ""
                    );
                    notification.success({ message: "Copied to clipboard" });
                  }}
                  icon={<img src={iconCopy} alt="" width={10} height={10} />}
                />
              </div>
            )}
        </div>
        {renderDocumentStatus(findLender)}
        {findLender?.submissionType?.includes("API") &&
          (findLender?.lenderApplicationId?.length ?? 0) > 0 && (
            <span>
              {(["BU9709497975", "BU3620294738"]?.includes(
                findLender?.lenderInfoId
              ) ||
                findLender?.lenderName?.toLowerCase()?.includes("fibe") ||
                findLender?.lenderName?.toLowerCase()?.includes("moneywide") ||
                findLender?.lenderName?.toLowerCase()?.includes("cashe") ||
                findLender?.lenderName?.toLowerCase()?.includes("kreditbee") ||
                findLender?.lenderName
                  ?.toLowerCase()
                  ?.includes("credilio")) && (
                  <>
                    <a
                      onClick={() =>
                        getRedirectURLLink(
                          findLender?.lenderInfoId,
                          leadData?.loanProductId,
                          findLender?.lenderApplicationId
                        )
                      }
                      className="small"
                      style={commonFontSize}
                    >
                      {findLender?.lenderName?.split(" ")[0]} Dashboard
                    </a>
                    <Button
                      size="small"
                      style={{ margin: "2px", border: "none" }}
                      onClick={() =>
                        getRedirectURLLink(
                          findLender?.lenderInfoId,
                          leadData?.loanProductId,
                          findLender?.lenderApplicationId,
                          true
                        )
                      }
                      icon={
                        <img
                          src={shareIcon}
                          alt=""
                          width={18}
                          height={18}
                          style={{ marginTop: "-4px" }}
                        />
                      }
                    />
                    {findLender?.lenderName?.toLowerCase()?.includes("cashe") && (
                      <Button
                        size="small"
                        style={{ margin: "2px", border: "none" }}
                        onClick={() =>
                          getCashETriggerSMSLink(findLender?.lenderApplicationId)
                        }
                        icon={
                          <img
                            src={shareIcon}
                            alt=""
                            width={18}
                            height={18}
                            style={{ marginTop: "-4px" }}
                          />
                        }
                      />
                    )}
                  </>
                )}
            </span>
          )}
      </div>
    );
  };

  const columns: ColumnsType<any> = [
    {
      title: "Lender Name",
      dataIndex: "lenderName",
      width: 180,
      sorter: (a: any, b: any) => a.lenderName.localeCompare(b.lenderName),
      render: (_: any, rec: any) => renderLenderNames(rec),
    },
    {
      title: "Purpose",
      dataIndex: "decisioningPurpose",
      render: (rec: any) => (
        <span style={commonFontSize}>
          {getEnumValue("DECISIONING_PURPOSE", rec ?? "")}
        </span>
      ),
    },
    {
      title: "Score",
      dataIndex: "score",
      render: (rec: any) => <span style={commonFontSize}>{rec}</span>,
    },
    {
      title: "Max Loan Amount",
      dataIndex: "maxLoanAmount",
      sorter: (a: any, b: any) => a.maxLoanAmount - b.maxLoanAmount,
      render: (rec: any) => {
        if ((rec ?? 0) == 0) {
          return <></>;
        }
        return <span style={commonFontSize}>Rs. {numberWithCommas(rec)}</span>;
      },
    },
    {
      title: "Total EMI",
      dataIndex: "totalEmi",
      render: (rec: any) => <span style={commonFontSize}>{rec}</span>,
    },
    {
      title: "Tenure (in years)",
      dataIndex: "tenureInMonths",
      render: (rec: any) => <span style={commonFontSize}>{rec}</span>,
    },
    {
      title: "ROI %",
      dataIndex: "roi",
      render: (rec: any) => <span style={commonFontSize}>{rec}</span>,
    },
  ];

  const handleSearch = (value: any) => {
    const filterData = listDecisioningData?.filter((item: any) => {
      return item?.lenderName
        ?.toLowerCase()
        ?.replace(/\s/g, "")
        ?.includes(value?.searchParam?.toLowerCase()?.replace(/\s/g, ""));
    });
    setFilterListDecision(filterData);
    setSearchValue(value?.searchParam);
    setTotalCount(filterData?.length ?? 0);
    setPageNo(1);
  };
  const customStyles: any = {'--bgColor': appColors?.appPrimaryColor ?? ""};
  return (
    <>
      {/* <div></div> */}
      {showDocumentStatus && (
        <DocumentStatus
          showDocumentStatus={showDocumentStatus}
          setShowDocumentStatus={setShowDocumentStatus}
          lenderInfoId={selectedLender?.lenderInfoId}
          caseId={leadData?.caseDetails?.caseId}
          documentDetails={documentDetails}
        />
      )}
      <Form layout="inline" style={{ padding: "10px" }} onFinish={handleSearch}>
        <Form.Item name="searchParam">
          <Input
            size="large"
            style={{ borderRadius: "8px" }}
            prefix={<img src={iconSearch} alt="" width={17} />}
            placeholder="Search Lender"
            allowClear
          />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" size="large"  className="dynamic-btn-primary-default" style={customStyles}>
            Search
          </Button>
        </Form.Item>
      </Form>
      <Table
        columns={columns}
        dataSource={
          (filterListDecision?.length ?? 0) > 0
            ? filterListDecision
            : listDecisioningData
        }
        size="small"
        onChange={(pagination: any) => {
          handlePaginationChange(pagination.current, pagination.pageSize);
        }}
        pagination={{
          current: pageNo,
          pageSize: pageSize,
          total: totalCount,
          showSizeChanger: true,
        }}
        scroll={{ x: "max-content" }}
        bordered
        className="table-bg-transparent dynamic-pagination-checkbox"
        style={customStyles}
      />
    </>
  );
};

export default Login;

import { Space, Tabs, Tooltip, notification } from "antd";
import { useEffect, useState } from "react";
import API_SERVICE from "shared/services/api-service";
import info from "../../../../assets/images/info.svg";
import Login from "./Login";
import { useSelector } from "react-redux";
import { RootState } from "shared/redux/store";


interface ILoginTabs {
    leadData: any;
    updateCase: any;
    getRedirectURLLink: any;
    getCashETriggerSMSLink: any;
    callbackReqDoc: any;
    callbackAdditionalField: any;
    setShowRequirementDocs: any;
    setSelectedLander: any;
    setAdditionalFields: any;
    setCurrLenderId: any;
    setCurrLeadId: any;
    setShowAdditionalFields: any;
    setIsLoading:any;
}

const LoginTabs: React.FC<ILoginTabs> = (props: ILoginTabs) => {
    const {
        leadData,
        updateCase,
        getRedirectURLLink,
        getCashETriggerSMSLink,
        callbackReqDoc,
        callbackAdditionalField,
        setShowRequirementDocs,
        setSelectedLander,
        setAdditionalFields,
        setCurrLenderId,
        setCurrLeadId,
        setShowAdditionalFields,
        setIsLoading
    } = props;
    const [decisioningData, setDescisioningData] = useState([] as any);
    const [isLoginTabsListDecisioning, setIsLoginTabsListDecisioning] = useState(false);
    const [totalCount, setTotalCount] = useState(0);
    const [loginTabkey, setLoginTabkey] = useState(leadData?.journeyConfigResponseList?.at(0)?.journeyId);
    const { appColors } = useSelector((state: RootState) => state.common);
    const [isLoading, setisLoading] = useState(false)
    
    useEffect(() => {
        listDecisioning()
    }, []);

    const listDecisioning = () => {
        let tmpId = leadData?.journeyConfigResponseList?.map((item: any) => {
            return item?.journeyId
        })
        setisLoading(true);
        API_SERVICE.listDecisioning(leadData?.caseDetails?.caseId, false, '', tmpId)
            .then(({ data }) => {
                setLoginTabkey(data?.payload?.journeyWiseDecisioningDataList?.at(0)?.journeyId);
                setDescisioningData(data?.payload?.journeyWiseDecisioningDataList);
                setIsLoginTabsListDecisioning(true);
            })
            .catch((e: any) => {
                notification.error({ message: API_SERVICE?.handleErrors(e) });
            })
            .finally(()=>setisLoading(false));
    };
    const tabStyle: any = {'--borderColor': appColors?.appPrimaryColor ?? ""};

    return (
        <Tabs
            className="lender-sub-tabs"
            size="small"
            style={tabStyle}
            activeKey={loginTabkey}
            onChange={(key) => { setLoginTabkey(key); setIsLoginTabsListDecisioning(true) }}
        >
            {leadData?.journeyConfigResponseList?.map((data: any) => {
                let tmpFindData = decisioningData?.find((item: any) =>
                    item?.journeyId == data?.journeyId
                )
                return (
                <Tabs.TabPane
                    key={data?.journeyId}
                    tab={data?.journeyName}
                >
                    <div className="tab-Userdetails">
                        <Space className="userdetails">
                            <p style={{ color: `${tmpFindData?.isDsaCaseUpdatedAfterExecutingDecisioning ? "red" : ""}` }}>Date/Time: {tmpFindData?.executionTime}</p>
                                {tmpFindData?.isDsaCaseUpdatedAfterExecutingDecisioning && (
                                    <Tooltip
                                        title="Something has been changed please edit the case"
                                        placement="topLeft"
                                        color="#1A3175"
                                    >
                                        <img src={info} style={{ marginBottom: "8px" }} />
                                    </Tooltip>
                                )}
                            <p>Executed By: {tmpFindData?.executedBy}</p>
                        </Space>
                    </div>
                    <Login
                        setIsLoading={setIsLoading}
                        leadData={leadData}
                        updateCase={updateCase}
                        getRedirectURLLink={getRedirectURLLink}
                        getCashETriggerSMSLink={getCashETriggerSMSLink}
                        callbackReqDoc={callbackReqDoc}
                        callbackAdditionalField={callbackAdditionalField}
                        setShowRequirementDocs={setShowRequirementDocs}
                        setSelectedLander={setSelectedLander}
                        setAdditionalFields={setAdditionalFields}
                        setCurrLenderId={setCurrLenderId}
                        setCurrLeadId={setCurrLeadId}
                        setShowAdditionalFields={setShowAdditionalFields}
                        decisioningDataList={tmpFindData?.decisioningDataListResponseList ?? []}
                        setTotalCount={setTotalCount}
                        totalCount={totalCount}
                        setIsLoginTabsListDecisioning={setIsLoginTabsListDecisioning}
                        isLoginTabsListDecisioning={isLoginTabsListDecisioning}
                    />
                </Tabs.TabPane>
                )
            })}
        </Tabs>
    )
}

export default LoginTabs;
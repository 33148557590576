import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { getBasicDetails, getEnumList } from "shared/services/Utility";
import {
  AutoComplete,
  Button,
  Card,
  Col,
  Form,
  RadioChangeEvent,
  Row,
  Select,
  Space,
  Modal,
  notification,
  Radio,
  Collapse
} from "antd";
import { PHONE_NUMBER_REGEX, STRING_ONLY } from "shared/constants/AppConstants";
import API_SERVICE from "shared/services/api-service";
import {ReactComponent as IconBil} from "../../assets/dashboard/bil.svg";
import {ReactComponent as IconSmallBil} from "../../assets/dashboard/small_bil.svg";
import {ReactComponent as IconHomeLoan} from "../../assets/dashboard/home_loan.svg";
import {ReactComponent as IconLap} from "../../assets/dashboard/lap.svg";
import {ReactComponent as IconCommercialPurchase} from "../../assets/dashboard/commercial_purchase.svg";
import {ReactComponent as IconCreditCard} from "../../assets/dashboard/credit_card.svg";
import {ReactComponent as IconLifeInsurance} from "../../assets/dashboard/life_insurance.svg";
import {ReactComponent as IconServiceOrderFinancing} from "../../assets/dashboard/service_order_financing.svg";
import {ReactComponent as IconUnsecureOverdraft} from "../../assets/dashboard/unsecure_overdraft.svg";
import {ReactComponent as IconWorkingCapital} from "../../assets/dashboard/working_capital.svg";
import {ReactComponent as IconSupplyChainFinance} from "../../assets/dashboard/supply_chain_finance.svg";
import {ReactComponent as IconLas} from "../../assets/dashboard/las.svg";
import {ReactComponent as IconRealty} from "../../assets/dashboard/realty.svg";
import {ReactComponent as IconPL} from "../../assets/dashboard/personal_loan.svg";
import {ReactComponent as IconEL} from "../../assets/dashboard/education_loan.svg";
import {ReactComponent as Iconbnpl} from "../../assets/dashboard/bnpl.svg";
import {ReactComponent as IconHealthInsurance} from "../../assets/dashboard/health_insurance.svg";
import {ReactComponent as IconTwoInsurance} from "../../assets/dashboard/two_insurance.svg";
import {ReactComponent as IconFourInsurance} from "../../assets/dashboard/four_insurance.svg";
import {ReactComponent as IconAutoLoan} from "../../assets/dashboard/auto_loan.svg";
import {ReactComponent as IconNewProduct} from "../../assets/dashboard/new-product.svg";
import {ReactComponent as IconMicroLAP} from "../../assets/images/Micro-lap.svg";
import rightArrow from "../../assets/images/ph_arrow-right.svg";
import FormInput from "shared/components/FormInput/FormInput";
import FormRadio from "shared/components/FormRadio/FormRadioStart";
import AddLeadV1 from "./AddLeadV1";
import "./AddLeadNew.scss";
import "./ProductRadioStart.scss";
import "shared/components/FormRadio/FormRadioStart.scss";
import AppLoading from "shared/components/AppLoading/AppLoading";
import { setScreenHeader } from "shared/redux/common-reducer";
import { useDispatch, useSelector } from "react-redux";
import FormSelect from "shared/components/FormSelect/FormSelect";
import { RootState } from "shared/redux/store";

const { Panel } = Collapse;

export type viewTypeScreen = "createUser" | "loanTypeList" | "manageLead";

interface IAddLeadNew {
  isEditV2?: any;
  setIsEditV2?: any;
  editData?: any;
  editLead: any;
}

const AddLeadNew: React.FC<IAddLeadNew> = (props: IAddLeadNew) => {
  const { isEditV2, setIsEditV2, editData, editLead } = props;
  const [viewType, setViewType] = useState<viewTypeScreen>("createUser");
  const [loading, setLoading] = useState(false);
  const [searchedCustomers, setSearchedCustomers] = useState([] as any);
  const [userData, setUserData] = useState({} as any);
  const [viewModel, setViewModel] = useState(false);
  const [isDisableSelectProduct, setIsDisableSelectProduct] = useState(true);
  const [loanType, setLoanType] = useState("");
  const [productVersion, setProductVersion] = useState("");
  const [caseId, setCaseId] = useState("");
  const history = useHistory();
  const [addLeadForm] = Form.useForm();
  const [productForm] = Form.useForm();
  let userDetails = localStorage.getItem("user") as any;
  if (userDetails) {
    userDetails = JSON.parse(userDetails);
  }
  const [selectedLeadApplicationType, setSelectedLeadApplicationType] =
    useState("");
  const [applicantTypesBusinessValue, setApplicantTypesBusinessValue] =
    useState("");
  const [applicantTypesIndividualValue, setApplicantTypesIndividualValue] =
    useState("");
  const [individualTypes, setIndividualType] = useState([]);
  const [businessTypes, setBusinessTypes] = useState([]);
  const [applicantTypes, setApplicantType] = useState([]);
  const { appColors }: any = useSelector((state: RootState) => state.common);
  const isMobile: boolean = useSelector(
    (state: RootState) => state.common.isMobile
  );

  const [activeKey, setActiveKey] = useState(['applicantForm'] as any);

  const dispatch = useDispatch();

  const getAllTypes = () => {
    API_SERVICE.getTypes().then(({ data }) => {
      if (data) {
        localStorage.setItem("enumValues", JSON.stringify(data));
        setEnumValues();
      }
    });
  };

  useEffect(() => {
    getAllTypes();
    setEnumValues();
    console.log("userDetails: ", userDetails)
    if(userDetails?.loanProductId) {
      productForm.setFieldsValue({
        loanType: userDetails?.loanProductId,
      })
    }
    if (isEditV2) {
      setCaseId(editData?.caseDetails?.caseId);
      setIsDisableSelectProduct(false);
      addLeadForm.setFieldsValue({
        applicantType: editData?.applicationType,
        selectType: editData?.sourceOfCase,
        businessType: editData?.applicationSubTypeKey,
        // selfPhoneNumber: editData?.contactDetails?.mobileNumber,
        // selfCustomerName: editData?.contactDetails?.firstName,
        assignTo:
          editData?.caseDetails?.connectorDetails?.userDetails?.firstName,
        assignToBackOffice:
          editData?.caseDetails?.backOfficeUserDetails?.fullName,
      });
    } else {
      dispatch(
        setScreenHeader({
          backScreenTitle: "Lead",
          backScreenPath: "/lead",
          screenTitle: "Add Lead",
        })
      );
      addLeadForm.setFieldsValue({
        productRadio: "YES",
        selectType: "CONNECTOR"//"SELF",
      });
    }
  }, []);

  const setEnumValues = () => {
    let enumValues = localStorage.getItem("enumValues") as any;
    if (enumValues) {
      enumValues = JSON.parse(enumValues);
      let filteredApplicationType = enumValues.find(
        (item: any) => item.propertyName === "APPLICATION_TYPE"
      );
      let filteredBusinessType = enumValues.find(
        (item: any) => item.propertyName === "BUSINESS_TYPE"
      );
      let filteredIndivType = enumValues.find(
        (item: any) => item.propertyName === "INDIVIDUAL_TYPE"
      );
      if (filteredIndivType) {
        setIndividualType(filteredIndivType.keyAndDisplayValueList);
      }
      if (filteredBusinessType) {
        setBusinessTypes(filteredBusinessType.keyAndDisplayValueList);
      }
      if (filteredApplicationType) {
        setApplicantType(filteredApplicationType.keyAndDisplayValueList);
      }
    }
  };
 
  const onFinishForm = (values: any) => {
    console.log(getBasicDetails().srmId);
    const selfPayload = {
      selectType: values?.selectType,
      productRadio: values?.productRadio,
      selfCustomerName: values?.selfCustomerName,
      selfPhoneNumber: values?.selfPhoneNumber,
      assignTo: getBasicDetails()?.srmId,
      assignToBackOffice: "",
      connectorCustomerName: values?.connectorCustomerName,
      connectorId: userDetails?.userDetails?.userId,
      mobileNumber: values?.mobileNumber,
      searchCustomer: values?.searchCustomer,
      tentativePayout: values?.tentativePayout,
      loanType: values?.loanType,
      applicantType: selectedLeadApplicationType,
      individualType: applicantTypesIndividualValue
        ? applicantTypesIndividualValue
        : "",
      businessType: applicantTypesBusinessValue
        ? applicantTypesBusinessValue
        : "",
    };
    continueToProductScreen(selfPayload);
  };

  
  const onSearchExistingCustomer = (e: any) => {
    API_SERVICE.searchCustomers(e).then(({ data }: any) => {
      setSearchedCustomers(data?.payload ?? []);
      addLeadForm.setFieldsValue({
        selfCustomerName: data?.payload?.at(0)?.contactDetails?.firstName,
      });
    });
  };

    
  const goToManageLeads = (values: any) => {
    setLoading(true);
    API_SERVICE.updateLoanProductandStartFlowable(caseId, values?.loanType)
      .then(({ data }) => {
        setProductVersion(data?.payload?.productVersion);
        setUserData(data?.payload);
        setLoanType(data?.payload?.loanType);
        setViewType("manageLead");
      })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const continueToProductScreen = (userData: any) => {
    const payload = {
      sourceOfCase: "CONNECTOR",
      assignToUserId: getBasicDetails().srmId,
      backOfficeUserId: userData?.assignToBackOffice,
      assignToBackOffice:
        userData?.caseDetails?.backOfficeUserDetails?.fullName,
      commissionDetail: {
        connectorName:userData?.connectorCustomerName,
          // userData?.selectType != "SELF"
          //   ? userData?.selfCustomerName
            // : userData?.connectorCustomerName,
        finalCommissionAmount: 0,
        finalCommissionPercentage: 0,
        tentativeCommissionAmount: 0,
        tentativeCommissionPercentage: 0,
      },
      connectorId: userDetails?.userDetails?.userId,
      customerId: "",
      contactDetails: {
        gender: "M",
        firstName: userData?.selfCustomerName,
        middleName: "",
        lastName: "",
        dateOfBirth: "",
        emailId: "",
        mobileNumber: userData?.selfPhoneNumber,
        pan: "",
      },
      applicationType: userData?.applicantType,
      applicationSubType:
        userData.applicantType === "BUSINESS"
          ? userData.businessType
          : userData.individualType,
      entityOrEmployerName: "",
      loanDetails: {
        expectedInterestRate: userData?.tentativePayout,
        loanAmount: null,
        loanTenureInYears: null,
        monthlyInHandSalary: null,
        currentEmis: null,
        educationLoanDetails: {
          admissionStatus: null,
          placeOfStudy: null,
          timeOfStudy: "",
        },
      },
    };
    setLoading(true);
    API_SERVICE.addCreateUserDetails(payload)
      .then(({ data }) => {
        notification.success({ message: data?.message });
        setCaseId(data?.payload?.caseDetails?.caseId);
        setViewType("loanTypeList");
        setViewModel(false);
        setIsDisableSelectProduct(false);
        setActiveKey("productForm")
      })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onChangeApplicationType = (e: any) => {
    setSelectedLeadApplicationType(e);
  };

  const onChangeIndividualType = (e: any) => {
    setApplicantTypesIndividualValue(e);
  };

  const onChangeBusinessType = (e: any) => {
    setApplicantTypesBusinessValue(e);
  };

  const renderSelfForm = () => {
    return (
      <>
        <FormSelect
          name="applicantType"
          label="Applicant Type"
          placeholder="Select Applicant Types"
          options={applicantTypes}
          onSelect={onChangeApplicationType}
          removeDefaultCss
          isRequired
        />

        {selectedLeadApplicationType === "INDIVIDUAL" ? (
          <FormSelect
            name="individualType"
            label="Individual Type"
            placeholder="Select Individual Types"
            optionFilterProp="children"
            options={individualTypes}
            onSelect={onChangeIndividualType}
            removeDefaultCss
            isRequired
          />
        ) : (
          <FormSelect
            name="businessType"
            label="Business Type"
            placeholder="Select Business Types"
            optionFilterProp="children"
            options={businessTypes}
            onSelect={onChangeBusinessType}
            removeDefaultCss
            isRequired
          />
        )}
     
         <FormInput
          name="selfPhoneNumber"
          label="Mobile Number"
          placeholder="Enter Mobile Number"
          removeDefaultCss
          onChange={(e: any) => {
            if (e?.target?.value?.length == 10) {
              onSearchExistingCustomer(e?.target?.value);
              console.log("data", searchedCustomers?.at(0)?.contactDetails);
            }
          }}
          rules={[
            {
              required: true,
              message: "Please enter Mobile Number",
            },
            {
              pattern: PHONE_NUMBER_REGEX,
              message: "Please enter valid Mobile number",
            },
          ]}
        />
        <FormInput
          name="selfCustomerName"
          label="Applicant Name as per PAN"
          placeholder="Enter Applicant Name as per PAN"
          removeDefaultCss
          isRequired
          rules={[
            {
              pattern: STRING_ONLY,
              message: "Please enter valid Applicant Name as per PAN",
            }
          ]}
        />
        
      </>
    );
  };

  const setIcon = (Icon: any, strokeFill: boolean = false) => {
    return (
      <Icon
        height={17}
        width={17}
        className={strokeFill ? "stroke-fill" : ""}
      />
    );
  };

  const getLoanIcon = (name: string) => {
    if (name == "Business Installment Loan") {
      return setIcon(IconBil);
    } else if (name == "Small Business Loan") {
      return setIcon(IconSmallBil, true);
    } else if (name == "Home loan") {
      return setIcon(IconHomeLoan, true);
    } else if (name == "Loan Against Property") {
      return setIcon(IconLap);
    } else if (name == "Commercial Purchase") {
      return setIcon(IconCommercialPurchase, true);
    } else if (name == "Credit Card") {
      return setIcon(IconCreditCard, true);
    } else if (name == "Life Insurance Policy") {
      return setIcon(IconLifeInsurance);
    } else if (name == "Service order financing" || name == "Service order  financing") {
      return setIcon(IconServiceOrderFinancing, true);
    } else if (name == "Unsecured Overdraft") {
      return setIcon(IconUnsecureOverdraft);
    } else if (name == "Working Capital Overdraft") {
      return setIcon(IconWorkingCapital);
    } else if (name == "Supply Chain Finance") {
      return setIcon(IconSupplyChainFinance, true);
    } else if (name == "Loan Against Securities") {
      return setIcon(IconLas, true);
    } else if (name == "Realty") {
      return setIcon(IconRealty, true);
    } else if (name == "Personal Loan") {
      return setIcon(IconPL);
    } else if (name == "Education Loan") {
      return setIcon(IconEL, true);
    } else if (name == "Buy Now Pay Later") {
      return setIcon(Iconbnpl, true);
    } else if (name == "Auto loan") {
      return setIcon(IconAutoLoan, true);
    } else if (name == "Two Wheeler Insurance") {
      return setIcon(IconTwoInsurance, true);
    } else if (name == "Four Wheeler Insurance") {
      return setIcon(IconFourInsurance, true);
    } else if (name == "Health Insurance") {
      return setIcon(IconHealthInsurance);
    } else if (name == "Micro LAP") {
      return setIcon(IconMicroLAP);
    }
    return setIcon(IconNewProduct, true);
  };


  const createUserForm = () =>{
    return(
      <Form
      layout="vertical"
      onFinish={onFinishForm}
      form={addLeadForm}
      initialValues={{
        assignTo: userDetails?.userDetails?.fullName,
      }}
    >
      <Card className="custom-app-card lead-card" bordered={false}>
          {!isMobile &&
            <span className="lead-card-header">
              <h4>{isEditV2 ? "Applicant Details" : "Applicant Details"}</h4>
            </span>
          }
        <div className="form-box">
         {renderSelfForm()}
          <Form.Item style={{ marginTop: "30px" }}>
            <Space style={{display:"flex" ,justifyContent: "center"}}>
              <Button
                size="large"
                className={"button-submit dynamic-btn-default"+(isMobile ? ' button-form-mobileView' :'')}    
                style={customStylesBtn}
                // htmlType="button"
                onClick={() => setViewModel(true)}
              >
                Cancel
              </Button>
              <Button
                size="large"
                className={"button-submit dynamic-btn-primary"+(isMobile ? ' button-form-mobileView' :'')}   
                style={customStylesBtnPrimary}
                type="primary"              
                htmlType="submit"
              >
                Continue
              </Button>
            </Space>
          </Form.Item>
        </div>
      </Card>
    </Form>
    )
  }

  const selectProductForm = () => {
    const customStylesRadio: any = { '--color': appColors?.appPrimaryColor ?? "" };
    const customStylesProduct: any = {
      "--appPrimary": appColors?.appPrimaryColor ?? ""
    };

    return (
      <>
        {/* {isDisableSelectProduct && <div className="disabled-view" />} */}
        <Form form={productForm} layout="vertical" onFinish={goToManageLeads}>
          <Card
            className="custom-app-card lead-card"
            bordered={false}
            style={{
              background: "white",
            }}
          >
            {!isMobile && 
            <span className="lead-card-header">
              <h4 style={{color:isDisableSelectProduct?"gray":"black"}}>Select Product</h4>
            </span>}
            <div className={"lead-loanType" + (isMobile ? " lead-loanType-mobileView" : "")}>
              <Form.Item name={"loanType"}>
                <Radio.Group
                  className={"product-radio"}
                  style={customStylesRadio}
                  disabled={isDisableSelectProduct}
                >
                  <div style={{ display: "flex", flexWrap: "wrap", gap: "15px" }}>
                    <Space direction="vertical">
                      {getEnumList("LOAN_TYPE").map((option: any, i: number) => {
                        return (
                          <Radio key={i} value={option.key} className="custome-radio" style={customStylesProduct}>
                            {getLoanIcon(option.value)}{" "}
                            {option.value}
                          </Radio>
                        );
                      })}
                    </Space>
                  </div>
                </Radio.Group>
              </Form.Item>
            </div>
            <div style={{ display:"flex", gap: "10px", marginTop: "30px" ,justifyContent: isMobile ? "center" :"" }}>
              <Button
                size="large"
                className={"button-submit dynamic-btn-default"+(isMobile ? ' button-form-mobileView' :'')}   
                style={customStylesBtn}
                type="default"
                disabled={isDisableSelectProduct}
                onClick={() => setViewModel(true)}
              >
                Cancel
              </Button>
              <Button
                size="large"
                className={"button-submit dynamic-btn-primary"+(isMobile ? ' button-form-mobileView' :'')}  
                style={customStylesBtnPrimary}
                type="primary"
                disabled={isDisableSelectProduct}
                htmlType="submit"
              >
                Continue
              </Button>
            </div>
          </Card>
        </Form>
      </>
    )
  }

  const createUser = () => {
    const customStylesRadio: any = { '--bgColor': appColors?.appSecondaryColor ?? "" };
    return (
      <>
        {(isEditV2 || !isDisableSelectProduct) && (
          <div className="disabled-view" />
        )}
        {isMobile ?  (                  
            <Collapse expandIconPosition="right" defaultActiveKey={['applicantForm']} activeKey={activeKey}  style={{ width:"100%"}} className="collapse-mobileView">
              <Panel header="Applicant Details" key="applicantForm">
                {createUserForm()}
              </Panel>
            </Collapse>
        ) :(
          createUserForm()
        )}
       
      </>
    );
  };

  const selectProduct = () => {   
    return (
      <>
        {isMobile ? (
          // <div style={{ display: 'grid', justifyContent: 'center' }}>
            <Collapse expandIconPosition="right" activeKey={activeKey}  style={{ width:"100%"}} className="collapse-mobileView">
              <Panel header="Select Product" key="productForm">
                {selectProductForm()}
              </Panel>
            </Collapse>
          // </div>
        ) : (
         selectProductForm()
        )}
       
      </>
    );
  };

  const renderCreateUser = () => {
    return (
      <>
        <div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Row
              gutter={[24, 24]}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <Col span={10} xs={24} xl={10} style={{marginTop: !isMobile ? '12%' : ''}}>{createUser()}</Col>
              {!isMobile &&
                <Col
                  span={4}
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    alignContent: "center",
                    justifyContent: "center",
                  }}
                >
                  <span>
                    <img
                      src={rightArrow}
                      alt=""
                    // style={{ marginTop: "270px", marginLeft: "-99px" }}
                    />
                  </span>
                </Col>
              }
              <Col span={10} xs={24} xl={10}>{selectProduct()}</Col>
            </Row>
          </div>
        </div>
        {renderModel()}
      </>
    );
  };

  const renderModel = () => {
    return (
      <Modal
        width={300}
        visible={viewModel}
        closable={false}
        maskClosable={false}
        keyboard={false}
        footer={false}
        centered
      >
        <p className="msg">
          You will lose you entered data <br />
          if you wants to go Back
        </p>
        <div className="common-button">
          <Button
            size="large"
            className="button-submit dynamic-btn-default"
            style={customStylesBtn}
            type="default"
            onClick={() => setViewModel(false)}
          >
            Cancel
          </Button>
          <Button
            size="large"
            className="button-submit dynamic-btn-primary"
            style={customStylesBtnPrimary}
            type="primary"
            htmlType="submit"
            onClick={() => {
              if(history.length <= 2) {
                history.replace(`/lead`);
              } else {
                history.goBack()
              }
            }}
          >
            OK
          </Button>
        </div>
      </Modal>
    );
  };

  const renderView = () => {
    if (viewType == "manageLead") {
      if (productVersion == "V2") {
        return history.push(`/lead/details/${caseId}`);
      } else {
        return <AddLeadV1 setViewType={setViewType} userData={userData} />;
      }
    } else {
      return renderCreateUser();
    }
  };
  const customStylesBtn: any = { '--borderColor': appColors?.appSecondaryColor ?? "" };
  const customStylesBtnPrimary:any = {'--btnColor': appColors?.appPrimaryColor ?? ""};

  return (
    <div className="content-box" style={{ paddingBlock: "20px" }}>
      <>{renderView()}</>
      {loading && <AppLoading />}
    </div>
  );
};
export default AddLeadNew;
